<template>
	<div class="app-body">
		<div class="a-flex-rsbc a-ptb-6">
			<span class="a-fs-16 a-fw-b">角色管理</span>
			<div class="a-flex-rcc">
				<le-export-btn :pageParam="pageParam"></le-export-btn>
				<el-button icon="el-icon-plus" type="primary" class="s-btn-add a-ml-24" @click="handlerEditRole('add')">角色</el-button>
			</div>
		</div>
		<el-card class="el-main">
			<le-search-form @reset="handlerRest" @search="handlerSearch" id="leSearch">
				<le-company-under-select label="所属商户" v-model="pageParam.params.companyId"></le-company-under-select>
			</le-search-form>
			<le-pagview ref="rolelist" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.rolelistTable">
				<el-table ref="rolelistTable" :data="tableData" :highlight-current-row="true" v-sticky="{ top: 0, parent:'.el-card__body' }">
					<el-table-column prop="name" label="角色名称" min-width="100"></el-table-column>
					<el-table-column prop="description" label="角色描述" min-width="100"></el-table-column>
					<el-table-column prop="companyName" label="所属商户" min-width="150"></el-table-column>
					<el-table-column label="创建时间" width="200">
						<template slot-scope="{ row }">
							<span>{{ $getDay.getTimeStampToStr(row.createTime) }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="name" label="操作" width="100" fixed="right">
						<template slot-scope="scope">
							<el-tooltip class="item" effect="dark" content="详情" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="handlerRoleInfo(scope.row)" />
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="修改" placement="top">
                                <img @click="handlerEditRole('edit',scope.row)" src="../../assets/icon/edit.png" class="a-wh-16" />
                            </el-tooltip>
						</template>
					</el-table-column>
				</el-table>
				<template slot="footerLabel" slot-scope="scope">
					<div class="a-flex-rfsc" v-if="scope.val != -1">
						<span class="a-fs-12 a-c-normal">共</span>
						<span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{ scope.val }}</span>
						<span class="a-fs-12 a-c-normal">种角色</span>
					</div>
				</template>
			</le-pagview>
		</el-card>
	</div>
</template>

<script>
	let _this
	import {
		mapState,
		mapActions
	} from 'vuex';
	export default {
		data() {
			return {
				pageParam: {
					url: this.$Config.apiUrl.getRolePage,
					method: "post",
					params: {
						companyId: null, //所属商户
					},
					freshCtrl: 1,
				},
				tableData: [], //数据列表
			}
		},
		computed: {
			...mapState({
				company: state => state.company.company,
			})
		},
		created() {
			_this = this
			this.pageParam.params.companyId = this.company.id
		},
		activated () {
            this.pageParam.freshCtrl++;
        },
		methods: {
			//获取列表
			setTableData(data) {
				this.tableData = data;
			},
			//确认筛选
			handlerSearch() {
				this.$refs['rolelist'].pageNum = 1
				this.pageParam.freshCtrl++;
			},
			//重置筛选表单
			handlerRest() {
				this.pageParam.params = {
					companyId: null, //所属商户
				};
				this.handlerSearch()
			},
			//跳转详情
			handlerRoleInfo(datas) {
				this.$router.push({
					path: '/staffManage/staff-role-details',
                    query: {
                        id: datas?datas.id:'',
                        companyId: datas?datas.companyId:'',
                    }
				})
			},
			//编辑角色
			handlerEditRole(type, datas) {
				this.$router.push({
					path: '/staffManage/staff-role-edit',
                    query: {
                        id: datas?datas.id:'',
                        companyId: datas?datas.companyId:'',
                        editType: type
                    }
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.mgl150 {
		margin-left: 150px;
	}
	/deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
</style>
